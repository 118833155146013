import React, { Component } from 'react';

export default class Construccion extends Component {

    render() {

        return (<div className="row">
            <div className="col-xl-12">
                <div className="card">
                    <img src='images/construccion.jpg' width='100%' alt='brutus undercontruccion' />
                </div>
            </div>
        </div>)
    }
}